import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47404_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/404.vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91slug_93_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/[category]/[slug].vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91subcategory_93_47_91slug_93_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/[category]/[subcategory]/[slug].vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91page_93_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/[page].vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47index_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/index.vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47massMedia_47_91slug_93_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/massMedia/[slug].vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47news_47_91slug_93_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/news/[slug].vue?macro=true";
import { default as _47home_47bert_47rtk_45cc_45nuxt_47pages_47press_46vueMeta } from "/home/bert/rtk-cc-nuxt/pages/press.vue?macro=true";
export default [
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47404_46vueMeta?.name ?? "404",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47404_46vueMeta?.path ?? "/404",
    file: "/home/bert/rtk-cc-nuxt/pages/404.vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47404_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47404_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47404_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91slug_93_46vueMeta?.name ?? "category-slug",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91slug_93_46vueMeta?.path ?? "/:category/:slug",
    file: "/home/bert/rtk-cc-nuxt/pages/[category]/[slug].vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91slug_93_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91subcategory_93_47_91slug_93_46vueMeta?.name ?? "category-subcategory-slug",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91subcategory_93_47_91slug_93_46vueMeta?.path ?? "/:category/:subcategory/:slug",
    file: "/home/bert/rtk-cc-nuxt/pages/[category]/[subcategory]/[slug].vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91subcategory_93_47_91slug_93_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91subcategory_93_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91category_93_47_91subcategory_93_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/[category]/[subcategory]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91page_93_46vueMeta?.name ?? "page",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91page_93_46vueMeta?.path ?? "/:page",
    file: "/home/bert/rtk-cc-nuxt/pages/[page].vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91page_93_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91page_93_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47_91page_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47index_46vueMeta?.name ?? "index",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47index_46vueMeta?.path ?? "/",
    file: "/home/bert/rtk-cc-nuxt/pages/index.vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47index_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47index_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47massMedia_47_91slug_93_46vueMeta?.name ?? "massMedia-slug",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47massMedia_47_91slug_93_46vueMeta?.path ?? "/massMedia/:slug",
    file: "/home/bert/rtk-cc-nuxt/pages/massMedia/[slug].vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47massMedia_47_91slug_93_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47massMedia_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47massMedia_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/massMedia/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47news_47_91slug_93_46vueMeta?.name ?? "news-slug",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47news_47_91slug_93_46vueMeta?.path ?? "/news/:slug",
    file: "/home/bert/rtk-cc-nuxt/pages/news/[slug].vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47news_47_91slug_93_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47news_47_91slug_93_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47news_47_91slug_93_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: _47home_47bert_47rtk_45cc_45nuxt_47pages_47press_46vueMeta?.name ?? "press",
    path: _47home_47bert_47rtk_45cc_45nuxt_47pages_47press_46vueMeta?.path ?? "/press",
    file: "/home/bert/rtk-cc-nuxt/pages/press.vue",
    children: [],
    meta: _47home_47bert_47rtk_45cc_45nuxt_47pages_47press_46vueMeta,
    alias: _47home_47bert_47rtk_45cc_45nuxt_47pages_47press_46vueMeta?.alias || [],
    redirect: _47home_47bert_47rtk_45cc_45nuxt_47pages_47press_46vueMeta?.redirect || undefined,
    component: () => import("/home/bert/rtk-cc-nuxt/pages/press.vue").then(m => m.default || m)
  }
]