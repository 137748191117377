export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"type":"text/javascript","innerHTML":"((d)=>{if(window.ocWidgetOptions){return};var s = d.createElement(\"script\");s.type = \"text/javascript\";s.async = true;s.src =\"https://cc-o4.omnichat.tech/widget/bundle.js?rnd=\"+Math.random();d.getElementsByTagName(\"head\")[0].appendChild(s);window.ocWidgetOptions = { channel_id: 9 };})(document)"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"